import React, { SVGProps } from "react";

interface ILicenceProps {
  props?: SVGProps<SVGSVGElement>;
  className?: string;
}

export const Licence = ({ className, ...props }: ILicenceProps) => {
  return (
    <svg
      width="215"
      height="215"
      viewBox="0 0 215 215"
      fill="#D9DEFA"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M191.111 35.8334H23.8889C20.721 35.8334 17.6829 37.0918 15.4429 39.3318C13.2029 41.5718 11.9445 44.61 11.9445 47.7778V167.222C11.9445 170.39 13.2029 173.428 15.4429 175.668C17.6829 177.908 20.721 179.167 23.8889 179.167H113.472L116.876 174.986L122.431 168.178L121.893 167.222H23.8889V47.7778H191.111V98.9C195.649 101.836 199.682 105.487 203.056 109.71V47.7778C203.056 44.61 201.797 41.5718 199.557 39.3318C197.317 37.0918 194.279 35.8334 191.111 35.8334Z" />
      <path d="M41.8055 71.6666H143.333V81.2222H41.8055V71.6666Z" />
      <path d="M41.8055 95.5555H107.5V105.111H41.8055V95.5555Z" />
      <path d="M41.8055 137.361H101.528V146.917H41.8055V137.361Z" />
      <path d="M163.997 102.901C156.355 102.841 148.872 105.087 142.526 109.346C136.18 113.606 131.267 119.681 128.429 126.777C125.591 133.873 124.959 141.66 126.616 149.121C128.274 156.582 132.143 163.369 137.719 168.596L126.133 182.75L131.508 204.31L153.367 177.674C160.286 179.719 167.649 179.719 174.568 177.674L196.426 204.31L201.801 182.75L190.215 168.596C195.791 163.369 199.661 156.582 201.318 149.121C202.976 141.66 202.344 133.873 199.506 126.777C196.667 119.681 191.754 113.606 185.409 109.346C179.063 105.087 171.58 102.841 163.938 102.901H163.997ZM163.997 166.685C158.922 166.614 153.981 165.045 149.796 162.174C145.61 159.303 142.367 155.258 140.473 150.549C138.58 145.84 138.121 140.676 139.154 135.707C140.188 130.737 142.667 126.184 146.281 122.62C149.894 119.056 154.481 116.64 159.464 115.676C164.447 114.711 169.604 115.241 174.287 117.2C178.97 119.158 182.969 122.457 185.781 126.682C188.594 130.907 190.095 135.869 190.096 140.944C190.033 147.814 187.251 154.378 182.36 159.202C177.469 164.026 170.867 166.717 163.997 166.685Z" />
    </svg>
  );
};
