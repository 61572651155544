export const titles = {
  projectName: "БЕЛТОПАГЕНТ",
  aboutUs: "О НАС",
  offers: "ПРЕДЛОЖЕНИЯ",
  benefits: "ПРЕИМУЩЕСТВА",
  contacts: "КОНТАКТЫ",
};
export const texts = {
  main: {
    desc: "Трубы и трубная продукция для нефтегазовой и нефтехимической промышленности",
  },
  aboutUs: {
    text1:
      "Поставляемая нами продукция эксплуатируется в экстремальных условиях высоких и низких температур, повышенного давления и агрессивных сред, то есть там, где требуется особый подход к вопросам качества, надёжности и безопасности.",
    text2:
      "Наша продукция из различных видов сталей и сплавов (углеродистая и нержавеющая сталь, легированная и аустенитно-ферритная, дуплекс и супердуплекс, никелевые сплавы) производится исключительно по международным стандартам (API, ASTM, ANSI, DIN, EN, NACE, ANFOR).",
  },
  offers: {
    title: "МЫ ГОТОВЫ ПРЕДЛОЖИТЬ ВАМ",
    option1:
      "Специализированые разработки проектов различной степени сложности.",
    option2: "Экспертный подход при ведении заказов.",
    option3: "Гарантийное обслуживание высокого уровня.",
    option4: "Пакеты профессиональной документации.",
    option5: "Пакеты профессиональной документации.",
  },
  benefits: {
    title: "НАШИ КОНКУРЕНТНЫЕ ПРЕИМУЩЕСТВА",
    options: {
      option1:
        "Комплексная поставка труб, фитингов, фланцев, прокладок, крепежных деталей из различных марок и типов стали, а также тройников, переходов, материалов для покрытия, сварочных материалов.",
      option2:
        "Поставка продукции, отвечающей широкому диапазону требований по температурному режиму (от -100 до + 1250°С), а также продукции для работы в агрессивных средах, в том числе с сероводородом.",
      option3:
        "Поставляемая продукция проходит все необходимые испытания, включая визуальную проверку, гидравлические испытания, а также дополнительные испытания в соответствии с конкретными запросами: радиографический и ультразвуковой контроль, тест на коррозийную стойкость, сопротивляемость механическим воздействиям, спектроскопия и другие.",
      option4:
        "Перед отгрузкой продукция проходит индивидуальные испытания, маркировку, финальную обработку и упаковку;- мы проводим проверку всего поступающего материала, сертификатов и маркировок.",
      option5: "Сертификат EN 10204.3.1 выдаётся на каждую партию продукции.",
    },
  },
  contacts: {
    adress1: "220114, г. Минск,",
    adress2: "ул. Филимонова, 25б, пом. 510",
    phone: "+375 (29) 618-22-95",
    mail: "beltopagent@gmail.com",
    webSite: "beltopagent.by",
  },
};
