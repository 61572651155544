import React, { useEffect } from "react";
import "./index.css";
import { Pages } from "../../models.ts";

import { texts } from "../../theme/infoObjects/all.ts";
import { Header } from "../Header/Header.tsx";
import { Phone } from "../../theme/icons/Phone.tsx";
import { Mail } from "../../theme/icons/Mail.tsx";
import { WebIcon } from "../../theme/icons/WebIcon.tsx";
import { useInView } from "react-intersection-observer";

interface IConactsProps {
  setHeaderMode: (val?: "dark" | "light") => void;
  location: Pages;
  setLocation: (val: Pages) => void;
}

export const Contacts = ({
  setHeaderMode,
  location,
  setLocation,
}: IConactsProps) => {
  const { ref, inView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (inView) {
      setHeaderMode(undefined);
      setLocation(Pages.CONTACTS);
    } else {
      setHeaderMode("dark");
      setLocation(Pages.BENEFITS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const isHorisontalOrientation = window.innerHeight < window.innerWidth;

  return (
    <div className="contacts" id={Pages.CONTACTS} ref={ref}>
      <div
        className="contacts-content"
        style={{ flexDirection: isHorisontalOrientation ? "row" : "column" }}
      >
        <Header
          location={location}
          setLocation={setLocation}
          mode="light"
          styles={{
            flexDirection: "column",
            gap: 15,
            marginTop: 0,
            marginLeft: 0,
            position: "initial",
          }}
        />
        <div
          className="contacts-line"
          style={{
            minWidth: isHorisontalOrientation ? 1 : "100%",
            minHeight: isHorisontalOrientation ? "100%" : 1,
          }}
        />
        <div className="contacts-section">
          <p className="contacts-text">{texts.contacts.adress1}</p>
          <p className="contacts-text">{texts.contacts.adress2}</p>
        </div>
        <div
          className="contacts-line"
          style={{
            minWidth: isHorisontalOrientation ? 1 : "100%",
            minHeight: isHorisontalOrientation ? "100%" : 1,
          }}
        />
        <div className="contacts-section">
          <div className="contact-section-content">
            <Phone className="contact-link-icon" />
            <a className="contact-link" href="tel:+375296182295">
              {texts.contacts.phone}
            </a>
          </div>
          <div className="contact-section-content">
            <Mail className="contact-link-icon" />
            <a className="contact-link" href="mailto:beltopagent@gmail.com">
              {texts.contacts.mail}
            </a>
          </div>
          <div className="contact-section-content">
            <WebIcon className="contact-link-icon" />
            <a
              className="contact-link"
              href="http/beltopagent.by"
              target="_blank"
            >
              {texts.contacts.webSite}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
