import React, { SVGProps } from "react";

interface ILogoMiniProps {
  props?: SVGProps<SVGSVGElement>;
  className?: string;
  stroke?: string;
}

export const LogoMini = ({ className, stroke, ...props }: ILogoMiniProps) => {
  return (
    <svg
      width="40"
      height="38"
      viewBox="0 0 40 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...props}
    >
      <path
        d="M19.8032 28.6895C14.4713 28.6376 9.68095 27.4288 6.24875 25.5414C2.79601 23.6427 0.831786 21.131 0.85889 18.5044C0.885993 15.8777 2.90165 13.4049 6.39287 11.5738C9.86333 9.75365 14.6776 8.63856 20.0095 8.69053C25.3414 8.74251 30.1318 9.95122 33.564 11.8387C37.0167 13.7374 38.9809 16.2491 38.9538 18.8757C38.9267 21.5023 36.9111 23.9752 33.4198 25.8063C29.9494 27.6264 25.1351 28.7415 19.8032 28.6895Z"
        stroke={stroke ?? "#7B72DA"}
      />
      <path
        d="M19.9727 25.4978C16.3446 25.5895 13.064 24.9011 10.6967 23.7382C8.30529 22.5635 6.97232 20.9781 6.92812 19.3251C6.88392 17.6721 8.13036 16.0216 10.4558 14.7277C12.7578 13.4469 15.9971 12.5938 19.6252 12.5022C23.2534 12.4105 26.534 13.0989 28.9013 14.2618C31.2927 15.4365 32.6257 17.0219 32.6699 18.6749C32.7141 20.3279 31.4676 21.9784 29.1422 23.2723C26.8402 24.5531 23.6009 25.4062 19.9727 25.4978Z"
        stroke={stroke ?? "#7B72DA"}
      />
      <path
        d="M19.8646 21.4992C17.9259 21.5473 16.1856 21.2619 14.9438 20.7807C14.3218 20.5396 13.8488 20.2587 13.5371 19.967C13.2272 19.677 13.1054 19.406 13.0991 19.1661C13.0928 18.9262 13.2003 18.6495 13.4945 18.3445C13.7905 18.0378 14.2481 17.7338 14.8567 17.4622C16.0717 16.9201 17.7947 16.5489 19.7334 16.5008C21.6721 16.4527 23.4123 16.7381 24.6542 17.2193C25.2762 17.4603 25.7492 17.7413 26.0609 18.033C26.3708 18.323 26.4926 18.594 26.4989 18.8339C26.5052 19.0738 26.3977 19.3505 26.1035 19.6554C25.8075 19.9622 25.3499 20.2662 24.7413 20.5378C23.5263 21.0799 21.8032 21.4511 19.8646 21.4992Z"
        stroke={stroke ?? "#7B72DA"}
      />
      <path
        d="M22.3856 19C22.3856 20.8803 22.0485 22.5585 21.524 23.748C21.2615 24.3434 20.9618 24.7941 20.6543 25.0891C20.3493 25.3816 20.0611 25.5 19.799 25.5C19.5369 25.5 19.2487 25.3816 18.9437 25.0891C18.6362 24.7941 18.3365 24.3434 18.0739 23.748C17.5494 22.5585 17.2124 20.8803 17.2124 19C17.2124 17.1197 17.5494 15.4415 18.0739 14.252C18.3365 13.6566 18.6362 13.2059 18.9437 12.9109C19.2487 12.6184 19.5369 12.5 19.799 12.5C20.0611 12.5 20.3493 12.6184 20.6543 12.9109C20.9618 13.2059 21.2615 13.6566 21.524 14.252C22.0485 15.4415 22.3856 17.1197 22.3856 19Z"
        stroke={stroke ?? "#7B72DA"}
      />
      <path
        d="M26.501 19C26.501 22.5215 25.7092 25.6848 24.4542 27.9501C23.1897 30.2326 21.523 31.5 19.799 31.5C18.0749 31.5 16.4082 30.2326 15.1437 27.9501C13.8888 25.6848 13.0969 22.5215 13.0969 19C13.0969 15.4785 13.8888 12.3152 15.1437 10.0499C16.4082 7.76742 18.0749 6.5 19.799 6.5C21.523 6.5 23.1897 7.76742 24.4542 10.0499C25.7092 12.3152 26.501 15.4785 26.501 19Z"
        stroke={stroke ?? "#7B72DA"}
      />
      <path
        d="M29.5876 19C29.5876 24.1762 28.3936 28.8378 26.4888 32.1879C24.5757 35.5526 22.0096 37.5 19.2845 37.5C16.5595 37.5 13.9934 35.5526 12.0802 32.1879C10.1754 28.8378 8.98145 24.1762 8.98145 19C8.98145 13.8238 10.1754 9.1622 12.0802 5.81211C13.9934 2.4474 16.5595 0.5 19.2845 0.5C22.0096 0.5 24.5757 2.4474 26.4888 5.81211C28.3936 9.1622 29.5876 13.8238 29.5876 19Z"
        stroke={stroke ?? "#7B72DA"}
      />
    </svg>
  );
};
