import React, { SVGProps } from "react";

interface ISpeedometrProps {
  props?: SVGProps<SVGSVGElement>;
  className?: string;
}

export const WebIcon = ({ className, ...props }: ISpeedometrProps) => {
  return (
    <svg
      className={className}
      {...props}
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 4.65625C16.0642 4.65625 13.1943 5.52682 10.7533 7.15787C8.31222 8.78893 6.40966 11.1072 5.28617 13.8195C4.16268 16.5319 3.86873 19.5165 4.44148 22.3959C5.01422 25.2753 6.42795 27.9202 8.50389 29.9961C10.5798 32.0721 13.2247 33.4858 16.1041 34.0585C18.9835 34.6313 21.9681 34.3373 24.6805 33.2138C27.3928 32.0903 29.7111 30.1878 31.3421 27.7467C32.9732 25.3057 33.8438 22.4358 33.8438 19.5C33.8394 15.5645 32.2742 11.7915 29.4914 9.00866C26.7085 6.22585 22.9355 4.66057 19 4.65625ZM19 32.9499C18.0604 32.0459 15.5859 29.3266 14.3584 24.8438H23.6416C22.4141 29.3266 19.9396 32.0459 19 32.9499ZM14.0749 23.6562C13.5167 20.9135 13.5167 18.0865 14.0749 15.3438H23.9252C24.4834 18.0865 24.4834 20.9135 23.9252 23.6562H14.0749ZM19 6.05008C19.9396 6.95406 22.4141 9.67344 23.6416 14.1562H14.3584C15.5859 9.67344 18.0604 6.95406 19 6.05008ZM25.1468 15.3438H32.0091C32.8719 18.0474 32.8719 20.9526 32.0091 23.6562H25.1468C25.6592 20.9091 25.6592 18.0909 25.1468 15.3438ZM31.5667 14.1562H24.887C24.1378 11.1017 22.6499 8.27788 20.5541 5.93281C22.9517 6.21136 25.233 7.11867 27.1669 8.5628C29.1008 10.0069 30.6187 11.9366 31.5667 14.1562ZM17.4474 5.93281C15.3516 8.27788 13.8637 11.1017 13.1145 14.1562H6.43477C7.38279 11.9366 8.90067 10.0069 10.8346 8.5628C12.7685 7.11867 15.0498 6.21136 17.4474 5.93281ZM5.99094 15.3438H12.8532C12.3408 18.0909 12.3408 20.9091 12.8532 23.6562H5.99094C5.12814 20.9526 5.12814 18.0474 5.99094 15.3438ZM6.43626 24.8438H13.1159C13.8652 27.8983 15.3531 30.7221 17.4488 33.0672C15.0508 32.7891 12.7689 31.8821 10.8344 30.4379C8.89988 28.9937 7.38156 27.0638 6.43329 24.8438H6.43626ZM20.5556 33.0672C22.6503 30.7218 24.1372 27.898 24.8856 24.8438H31.5652C30.6172 27.0634 29.0993 28.9931 27.1654 30.4372C25.2315 31.8813 22.9502 32.7886 20.5527 33.0672H20.5556Z"
        fill="black"
      />
    </svg>
  );
};
